<template>
	<div
		class="choice-restaurants__wrapper"
		v-if="activeRestaurantid"
	>
		<div
			v-for="restaurant in showRestaurants"
			:key="restaurant.id"
			class="choice-restaurants"
			:class="{'choice-restaurants--select': activeRestaurantid === restaurant.id}"
			@click="selectRestaurants(restaurant.id)"
		>
			<div class="choice-restaurants__flex">
				<div class="choose-restaurants__icon">
					<img
						src="@/assets/img/icons/chooseRestIcon-1.svg"
						alt=""
						v-if="activeRestaurantid === restaurant.id"
					>
					<img
						src="@/assets/img/icons/chooseRestIcon.svg"
						alt=""
						v-else
					>
				</div>
				<div class="choice-restaurants__descrip">
					<span class="choice-restaurants__title">{{ restaurant.name }}</span>
					<span class="chice-restaurants__subtitle">{{ restaurant.address }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "ChoiceRestaurants",
	data()
	{
		return {
			currentRestaurantPicked: false,
		};
	},
	computed:
		{
			...mapGetters({
				restaurants: "getRestaurants",
				currentBasket: "getCurrentBasket",
			}),
			showRestaurants()
			{
				return this.restaurants.filter(restaurant => restaurant.showCollective);
			}
		},
	props:
		{
			activeRestaurantid: [String, Boolean],
		},
	methods: {
		selectRestaurants(restId)
		{
			this.$emit("changeRest", restId);
		},
	},
};
</script>

<style lang="scss">
.choice-restaurants__wrapper
{
	display: flex;
	gap: 16px;
	overflow-x: auto;
}

.choice-restaurants
{
	flex: 1;
	max-width: 258px;
	padding: 16px;
	background-color: $greyBackground;
	border-radius: 8px;
	cursor: pointer;
	border: 1px solid $greyDelimiter;

	&--select
	{
		background-color: $lightGreenBackground;
		border-color: $green;
	}
}

.choice-restaurants__flex
{
	display: flex;
	align-items: center;
	gap: 16px;
}

.choose-restaurants__icon
{
	align-self: flex-start;

	img
	{
		width: 24px;
		height: 24px;
	}
}

.choice-restaurants__descrip
{
	display: flex;
	flex-direction: column;

}

.choice-restaurants__title
{
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #3D4248;

	/* Ограничивает текст до двух строк */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.chice-restaurants__subtitle
{
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #979797;

	/* Ограничивает текст до двух строк */
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (max-width: 767px)
{
	.choice-restaurants__wrapper
	{flex-direction: column;}

	.choice-restaurants
	{max-width: 100%;}
}
</style>
